import { FormatOptionsValues } from "~/components/FormatForm";
import { FileSource } from "~/components/SelectFile";
import {
  ApiErrorResponse,
  ApiResponse,
  FileReadOptions,
  File,
} from "~/dataLayer";
import { Format, getFormatById } from "~/lib/format";
import type { InputOptions, QueryResponseData } from "~/.server/dataSource";

// style guide: action names should be namespaced by the state name
// and follow the format verb (+ noun)
export type Action =
  | {
      type: "selectingDataSource/selectFileSource";
      formatId: string;
      fileSource: FileSource | null;
      readOptions: FormatOptionsValues;
    }
  | {
      type: "selectingDataSource/selectIntegration";
      formatId: string;
    }
  | {
      type: "selectingFile/changeSource";
      fileSource: FileSource;
    }
  | {
      type: "selectingFile/changeReadOptions";
      readOptions: FormatOptionsValues;
    }
  | {
      type: "selectingFile/createFile";
      fileSource: FileSource;
      readOptions: FileReadOptions;
    }
  | { type: "selectingFile/error"; error: ApiErrorResponse["error"] }
  | { type: "creatingFile/error"; error: ApiErrorResponse["error"] }
  | {
      type: "creatingFile/startUpload";
      domFile: globalThis.File;
      file: File;
    }
  | {
      type: "creatingFile/uploading/progress";
      uploadedByteLength: number;
    }
  | {
      type: "viewFile";
      file: File;
    }
  | {
      type: "selectDataSource";
    }
  | { type: "viewingFile/clearQueryTextResponse" }
  | { type: "viewingFile/changeReadOptions"; readOptions: FileReadOptions }
  | {
      type: "viewingFile/toggleSuggestions";
    }
  | {
      type: "viewingFile/startQuery";
    }
  | {
      type: "viewingFile/completeQuery";
      response: ApiResponse<QueryResponseData>;
    }
  | {
      type: "viewingFile/suggestionsLoaded";
      suggestions: string[];
    };

// style guide: state names should describe what is ongoing
export type State =
  | {
      type: "selectingDataSource";
    }
  | {
      type: "selectingIntegration";
      formatId: string;
    }
  | {
      type: "selectingFile";
      fileSource: FileSource | null;
      formatId: string;
      readOptions: FormatOptionsValues;
      error: ApiErrorResponse["error"] | null;
    }
  | {
      type: "loadingFile";
      fileId: string;
    }
  | {
      type: "creatingFile";
      fileSource: FileSource;
      readOptions: FileReadOptions;
    }
  | {
      type: "creatingFile/loading";
      fileSource: FileSource;
      readOptions: FileReadOptions;
      file: File;
    }
  | {
      type: "creatingFile/uploading";
      fileSource: FileSource;
      file: File;
      domFile: globalThis.File;
      uploadedByteLength: number;
    }
  | {
      type: "viewingFile";
      file: File;
      readOptions: InputOptions;
      format: Format | null;
      queryResult: Exclude<
        QueryResponseData,
        { answer: { name: "chat_to_user" } }
      > | null;
      queryError: ApiErrorResponse["error"] | null;
      queryTextResponse: string | null;
      currentQuery: string | null;
      suggestions: string[] | null;
      suggestionsClosed: boolean;
      isLoading: boolean;
    };

function selectingFileReducer(
  state: Extract<State, { type: "selectingFile" }>,
  action: Action
): State {
  switch (action.type) {
    case "selectingFile/changeSource":
      return {
        ...state,
        fileSource: action.fileSource,
      };

    case "selectingFile/changeReadOptions":
      return {
        ...state,
        readOptions: action.readOptions,
      };

    case "selectingFile/createFile":
      return {
        type: "creatingFile",
        fileSource: action.fileSource,
        readOptions: action.readOptions,
      };

    case "selectingFile/error":
      return {
        ...state,
        error: action.error,
      };

    case "viewFile":
      return createViewFileState(action.file);

    case "selectDataSource":
      return {
        type: "selectingDataSource",
      };

    default:
      throw new Error(`Unknown action: ${action.type} in ${state.type}`);
  }
}

function selectingDataSourceReducer(
  state: Extract<State, { type: "selectingDataSource" }>,
  action: Action
): State {
  switch (action.type) {
    case "selectingDataSource/selectFileSource":
      return {
        type: "selectingFile",
        fileSource: action.fileSource,
        formatId: action.formatId,
        readOptions: action.readOptions,
        error: null,
      };

    case "selectingDataSource/selectIntegration":
      return {
        type: "selectingIntegration",
        formatId: action.formatId,
      };

    default:
      throw new Error(`Unknown action: ${action.type} in ${state.type}`);
  }
}

function createFileReducer(
  state: Extract<State, { type: "creatingFile" }>,
  action: Action
): State {
  switch (action.type) {
    case "creatingFile/error":
      return {
        type: "selectingFile",
        formatId: state.readOptions.format_id,
        fileSource: state.fileSource,
        readOptions: state.readOptions,
        error: action.error,
      };

    case "creatingFile/startUpload":
      return {
        type: "creatingFile/uploading",
        file: action.file,
        fileSource: state.fileSource,
        domFile: action.domFile,
        uploadedByteLength: 0,
      };

    case "viewFile":
      return createViewFileState(action.file);

    default:
      throw new Error(`Unknown action: ${action.type} in ${state.type}`);
  }
}

function createFileUploadingReducer(
  state: Extract<State, { type: "creatingFile/uploading" }>,
  action: Action
): State {
  switch (action.type) {
    case "creatingFile/uploading/progress":
      return {
        ...state,
        uploadedByteLength: action.uploadedByteLength,
      };

    case "creatingFile/error":
      return {
        type: "selectingFile",
        formatId: state.file.read_options.format_id,
        fileSource: state.fileSource,
        readOptions: state.file.read_options,
        error: action.error,
      };

    case "viewFile":
      return createViewFileState(action.file);

    default:
      throw new Error(`Unknown action: ${action.type} in ${state.type}`);
  }
}

export function viewingFileReducer(
  state: Extract<State, { type: "viewingFile" }>,
  action: Action
): Extract<State, { type: "viewingFile" }> {
  switch (action.type) {
    case "viewingFile/clearQueryTextResponse":
      return {
        ...state,
        queryTextResponse: null,
      };

    case "viewingFile/toggleSuggestions":
      return {
        ...state,
        suggestionsClosed: !state.suggestionsClosed,
      };

    case "viewingFile/startQuery":
      return {
        ...state,
        isLoading: true,
      };

    case "viewingFile/completeQuery":
      if (action.response.error === null) {
        if (action.response.data.answer.name === "chat_to_user") {
          return {
            ...state,
            queryError: null,
            queryTextResponse: action.response.data.answer.arguments.content,
            isLoading: false,
          };
        } else {
          const suggestions = Array.from(
            new Set(
              (
                action.response.data.answer.arguments
                  .suggested_follow_up_questions ?? []
              ).concat(state.suggestions ?? [])
            )
          ).slice(0, 5);
          return {
            ...state,
            // @ts-expect-error - response is a query response
            queryResult: action.response.data,
            currentQuery: action.response.data.answer.arguments.question,
            suggestions: suggestions,
            queryError: null,
            queryTextResponse: null,
            isLoading: false,
          };
        }
      } else {
        return {
          ...state,
          queryError: action.response.error,
          queryTextResponse: null,
          isLoading: false,
        };
      }

    case "viewingFile/suggestionsLoaded":
      return {
        ...state,
        suggestions: action.suggestions,
      };

    case "viewingFile/changeReadOptions":
      return {
        ...state,
        readOptions: action.readOptions,
      };

    case "viewFile":
      return createViewFileState(action.file);

    default:
      throw new Error(`Unknown action: ${action.type} in ${state.type}`);
  }
}

export function reducer(state: State, action: Action): State {
  if (process.env.NODE_ENV === "development") {
    console.debug("reducer", state, action);
  }

  switch (state.type) {
    case "selectingFile": {
      return selectingFileReducer(state, action);
    }

    case "selectingDataSource": {
      return selectingDataSourceReducer(state, action);
    }

    case "creatingFile": {
      return createFileReducer(state, action);
    }

    case "creatingFile/uploading": {
      return createFileUploadingReducer(state, action);
    }

    case "viewingFile": {
      return viewingFileReducer(state, action);
    }

    case "loadingFile": {
      switch (action.type) {
        case "viewFile":
          return createViewFileState(action.file);

        default:
          throw new Error(`Unknown action: ${action.type} in ${state.type}`);
      }
    }

    case "selectingIntegration": {
      switch (action.type) {
        case "selectDataSource":
          return {
            type: "selectingDataSource",
          };

        default:
          throw new Error(`Unknown action: ${action.type} in ${state.type}`);
      }
    }

    default: {
      throw new Error(`Unknown state: ${state}`);
    }
  }
}

export function createViewFileState(
  file: File
): Extract<State, { type: "viewingFile" }> {
  const readOptions = file.read_options;
  const format = getFormatById(readOptions.format_id);

  return {
    type: "viewingFile",
    isLoading: true,
    readOptions: readOptions,
    currentQuery: null,
    file,
    format,
    queryResult: null,
    queryError: null,
    queryTextResponse: null,
    suggestions: [],
    suggestionsClosed: false,
  };
}
